const data = {
  unicPay: 'Pago único',
  mothPay: 'Pago a meses',
  numDocumento: 'Número del documento',
  holderDocumento: 'Ingresa el número del documento',
  card: {
    titleCard: 'Tarjeta de crédito o débito',
    titleCardMonth: 'Tarjeta de crédito',
    secured: 'Pago seguro con encriptación SSL',
    policyCard: 'Conoce nuestra política de reembolso',
  },
  oxxo: {
    titleOxxo: 'Pago en efectivo',
    descriptionOxxo:
      'Utiliza tu referencia de pago para realizar tu pago en OXXO',
    stepsOxxo: [
      'Obtén tu referencia de pago',
      'Ve a cualquier sucursal OXXO e indica al cajero que deseas realizar el pago a través de un número de referencia  o muestra el código de barras',
      'Realiza tu pago y obtén la confirmación al instante',
    ],
    buttonOxxo: 'Obtener referencia de pago',
    policyOxxo: 'Conoce nuestra política de reembolso',
  },
  efecty: {
    titleEfecty: 'Pago con efectivo en Efecty',
    descriptionEfecty:
      'Utiliza tu información de pago para realizar tu pago en Efecty.',
    stepsEfecty: [
      'Obtén tu referencia de pago.',
      'Ve a cualquier sucursal Efecty y muestra el número de convenio y el número de pago.',
      'Realiza tu pago y obtén la confirmación al instante.',
    ],
    buttonEfecty: 'Obtener información de pago',
    policyEfecty: 'Conoce nuestra política de reembolso',
  },
  spei: {
    titleSpei: 'Transferencia interbancaria',
    descriptionSpei: 'Realiza el pago de tu pedido desde tu banca en línea',
    stepsSpei: [
      'Ingresa a tu banca en línea',
      'Utiliza la CLABE interbancaria para realizar la transferencia con el concepto especificado',
      'Una vez realizado tu pago, este tardará de 48 a 72 horas en reflejarse. Te enviaremos un email una vez esto suceda',
    ],
    buttonSpei: 'Obtener CLABE interbancaria',
    policySpei: 'Conoce nuestra política de reembolso',
  },
  atrato: {
    titleAtrato: 'Paga a meses sin tener tarjeta',
    descriptionAtrato:
      '¡Ya casi tendrás tu compra!, para obtener tu producto es necesario que completes el proceso de solicitud de crédito con Atrato.',
    buttonAtrato: 'Continuar con Atrato',
    policyAtrato: 'Conoce nuestra política de reembolso',
  },
  pse: {
    titlePSE: 'Pagos seguros en línea',
    descriptionPSE: 'Ingresa a PSE para completar tu pago.',
    stepsPSE: [
      'Haz clic en el botón “Ir a PSE”',
      'Completa toda tu información desde su plataforma',
      'Realiza tu pago y obtén la confirmación al instante',
    ],
    buttonPSE1: 'Continuar',
    buttonPSE2: 'Ir a PSE',
    policyPSE: 'Conoce nuestra política de reembolso',
  },
  paypal: {
    titlePayPal: 'Te redireccionaremos a PayPal para completar tu compra',
    buttonPayPal: 'PayPal',
    policyPayPal: 'Conoce nuestra política de reembolso',
  },
  efectivo: {
    titleEfectivo: 'Paga en efectivo vía MercadoPago',
    descriptionEfectivo:
      'Realiza tu pago presencial a través de PagoEfectivo Soles',
    stepsEfectivo: [
      'Ingresa a MercadoPago.',
      'Selecciona tu opción de pago presencial y descarga tu ticket.',
      'Ve a una sucursal de tu agente, bodega o agencia bancaria adherida e indica que realizarás un pago a PagoEfectivo Soles.',
    ],
    buttonEfectivo: 'Ir a MercadoPago',
    policyEfectivo: 'Conoce nuestra política de reembolso',
  },
  document: {
    CO: [
      {
        id: 0,
        label: 'Selecciona una opción',
        selected: false,
        value: 0,
        regex: null,
      },
      {
        id: 1,
        label: 'Cédula de ciudadanía',
        selected: false,
        value: 'CC',
        regex: 'num',
      },
      {
        id: 2,
        label: 'Cédula de extranjería',
        selected: false,
        value: 'CE',
        regex: 'alfa',
      },
      {
        id: 3,
        label: 'Número de Identificación Tributario',
        selected: false,
        value: 'NIT',
        regex: 'num',
      },
    ],
    PE: [
      {
        id: 0,
        label: 'Selecciona una opción',
        selected: false,
        value: 0,
        regex: null,
      },
      {
        id: 1,
        label: 'DNI',
        selected: false,
        value: 'DNI',
        regex: 'num',
      },
    ],
  },
};

export default data;
