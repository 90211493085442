/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { Button, Dropdown, Input } from '@mymoons/ui-library';
import { efecty, priceCheck } from '../../../../../../assets/images/Global';
import styles from '../../MethodsFlow.module.css';
import data from '../../data';
import axiosPost from '../../../../../../methods/cashPayments/mx/axiosPost';
import ThankYouContext from '../../../../../../context/ThankYou/Context';
import PaymentContext from '../../../../../../context/Payment/Context';
import ProductContext from '../../../../../../context/Product/Context';
import GlobalContext from '../../../../../../context/Global/Context';
import organizeDataPayments from '../../../../../../methods/organizeDataPayments';
import AlertModal from '../../../../../AlertModal';
import { axiosPutRecalculateCarts } from '../../../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../../../utils/stock';
import totalPriceCarts from '../../../../../../hooks/totalCarts';
import { availableCountries } from '../../../../../../utils/locale';

const FlowEfecty = () => {
  const paymentContext = useContext(PaymentContext);
  const thankYouContext = useContext(ThankYouContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const [loadingEfectyButton, setLoadingEfectyButton] = useState(false);
  const [preventModal, setPreventModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { titleEfecty, descriptionEfecty, stepsEfecty, buttonEfecty } =
    data.efecty;
  const [valuesSelected, setValuesSelected] = useState({
    document: {},
    number: '',
    isValid: false,
  });
  const [hasErrorDocumentNumber, setHasErrorDocumentNumber] = useState(null);
  const {
    country,
    name,
    email,
    customerId,
    publicKey,
    origin,
    setEmptyAddressFunction,
  } = globalContext;
  const { setDataEfecty } = thankYouContext;
  const { hasAddress } = paymentContext;
  const {
    carts: cartsProducts,
    couponsCode,
    productsWithDiscount,
  } = productContext;
  let dataToEfecty = organizeDataPayments();
  const totalPrice = totalPriceCarts(cartsProducts, false);
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl'
  );
  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    button: 'Regresar a la tienda',
    imgAlert: priceCheck,
    urlRedirect: buttonUrl,
    discardLink: buttonUrl,
  };

  useEffect(() => {
    if (productsWithDiscount.length !== 0) {
      const productsArray = [];

      productsWithDiscount.forEach((product) => {
        const { images } = product.variant;
        const { centAmount } = product.price.value;
        const productsConvert = {
          id: product.id,
          productId: product.productId,
          name: 'es-CO',
          price:
            product?.discountedPricePerQuantity[0]?.discountedPrice?.value
              .centAmount,
          quantity: product.quantity,
          totalPrice: centAmount * product.quantity,
          images,
        };
        productsArray.push(productsConvert);
      });

      dataToEfecty.cartData.totalLineItemQuantity = productsArray.length;
      dataToEfecty.cartData.lineItems = productsArray;
    }
  }, [couponsCode]);

  const formatDocumentNumber = (value, type) => {
    switch (type) {
      case 'NIT':
        if (value.length >= 10 && !value.includes('-')) {
          return value.slice(0, 10) + '-' + value.slice(10);
        }
        return value;
      default:
        return value;
    }
  };

  const validateDocumentNumber = (value) => {
    const documentType = valuesSelected.document.value;
    const formattedValue = formatDocumentNumber(value, documentType);

    const regexCitizen = /^[1-9]\d{7}$|^[1-9]\d{9}$/;
    const regexForeigner = /^[0-9]{6,10}$/;
    const regexNIT = /^\d{9,10}-\d$/;

    let isValid = false;
    let maxLength = 20;
    let errorMessage = '';

    switch (documentType) {
      case 'CC':
        isValid = regexCitizen.test(formattedValue);
        maxLength = 10;
        errorMessage = 'Ingrese una cédula de ciudadanía válida (8 o 10 dígitos)';
        break;
      case 'CE':
        isValid = regexForeigner.test(formattedValue);
        maxLength = 10;
        errorMessage = 'Ingrese una cédula de extranjería válida (6 o 10 dígitos)';
        break;
      case 'NIT':
        isValid = regexNIT.test(formattedValue);
        maxLength = 12;
        errorMessage = 'Ingrese un NIT válido';
        break;
      default:
        isValid = true;
    }

    setValuesSelected(prev => ({
      ...prev,
      number: formattedValue,
      isValid: isValid,
      errorMessage: !isValid && formattedValue !== '' ? errorMessage : ''
    }));
    setHasErrorDocumentNumber(!isValid && formattedValue !== '');

    return maxLength;
  };

  const getEfectyReference = () => {
    if (!hasAddress) {
      setEmptyAddressFunction(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(
          carts
        );

        if (areThereCartsWithoutEnoughStock(upToDateCartData.carts)) {
          setPreventModal(true);
        } else {
          setPreventModal(false);

          dataToEfecty.country = 'Colombia';
          dataToEfecty.discountPrice = couponsCode.length > 0 ? true : false;
          dataToEfecty.paymentMethodType = 'efecty';
          dataToEfecty.transactionType = 'product';
          dataToEfecty.productKeyName =
            cartsProducts[0]?.lineItems[0]?.productKey;

          dataToEfecty.payer.identification.docTypeCard = valuesSelected.document.value;
          dataToEfecty.payer.identification.docValueCard = valuesSelected.number.replace('-', '');
          setLoadingEfectyButton(true);
          axiosPost(dataToEfecty)
            .then((res) => {
              setDataEfecty(res.data);
              setLoadingEfectyButton(false);
              window.location.replace(res.data.mercadoPagoLink);
            })
            .catch(() => {
              setLoadingEfectyButton(false);
            });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setPreventModal(false);
      }
    })();
  };

  useEffect(() => {
    if (totalPrice > 4000000) {
      setDisableButton(true);
    }
  }, [totalPrice]);

  return (
    <>
      {preventModal && (
        <AlertModal
          data={outOfStockModal}
          modal={false}
          discardOption={false}
        />
      )}
      <div className={styles.titleContainer}>
        <p className={styles.title}>{titleEfecty}</p>
        <img
          className={styles.logo}
          width={67}
          src={efecty}
          alt="Efecty Logo"
        />
      </div>
      <div className={styles.container}>
      {(
          <>
            <div className={styles.inputMargin}>
              <Dropdown
                id="documnent_id"
                fullWidth
                label="Tipo de documento"
                options={data.document[country]}
                value={valuesSelected.document}
                onChange={(selection) => {
                  if (selection.id !== 0) {
                    setValuesSelected({
                      ...valuesSelected,
                      document: {
                        id: selection.id,
                        label: selection.label,
                        selected: true,
                        value: selection.value,
                        regex: selection.regex,
                      },
                      number: '',
                    });
                  }
                }}
              />
            </div>
            <br />
            <div className={styles.inputMargin}>
              <Input
                label="Número de documento"
                disabled={valuesSelected.document.id === 0}
                placeholder={data.holderDocumento}
                value={valuesSelected.number}
                onChange={(e) => {
                  const maxLength = validateDocumentNumber(e.target.value);
                  e.target.maxLength = maxLength;
                }}
                variant="outline"
                pattern={null}
                fullWidth
              />
            </div>
            <br />
            {valuesSelected.errorMessage && (
              <div className={styles.inputMargin}>
                <div className="ErrorContainer">
                  <span className="material-icons ErrorIcon">error</span>
                  <span className="ErrorMessage">{valuesSelected.errorMessage}</span>
                </div>
              </div>
            )}
            <br />
          </>
        )}
        <p className={styles.text}>{descriptionEfecty}</p>
        <ul className={styles.list}>
          {stepsEfecty.map((step, index) => (
            <li key={step} className={styles.text}>
              <div className={styles.circle}>{index + 1}</div>
              <div className={styles.stepText}>{step}</div>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.button}>
        <Button
          id="Check_Flow_GetReferenceOxxo"
          color="red"
          disabled={disableButton || !valuesSelected.isValid}
          fullWidth
          isButton
          label={buttonEfecty}
          rel="noopener noreferrer"
          size="medium"
          variant="filled"
          loading={loadingEfectyButton}
          onClick={() => getEfectyReference()}
        />
      </div>
    </>
  );
};

export default FlowEfecty;
